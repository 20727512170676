import React, { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import BookBgSvg from 'assets/icons/bookBackground.svg';
import { tablet, useQuery } from 'styles/breakpoints';

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  @media ${tablet} {
  }
`;
const StyledDynamicImageBackground = styled(BookBgSvg)<{ $height?: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 290px;
  @media ${tablet} {
    width: 260px;
    height: 225px;
  }
`;

const StyledDynamicImage = styled(DynamicImage)<{
  $width?: string;
  $height?: string;
}>`
  width: ${({ $width }) => $width || '200px'};
  height: ${({ $height }) => $height || '100%'};
  @media ${tablet} {
    width: ${({ $width }) => $width || '170px'};
    height: ${({ $height }) => $height || '100%'};
  }
`;

const BookTitle = styled(Text)<{
  $textColor: string;
  $fontSize?: string;
  $top?: string;
}>`
  position: absolute;
  top: ${({ $top }) => $top || '15px'};
  left: 50%;
  transform: translateX(-50%);
  color: ${({ $textColor }) => $textColor};
  max-width: 200px;
  width: 100%;
  word-wrap: break-word;
  text-align: center;
  text-transform: uppercase;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  font-size: ${({ $fontSize }) => $fontSize || '14px'};
  @media ${tablet} {
  }
`;

const EBookTag = styled(Text).attrs({
  type: 'body700',
  color: 'light0',
})`
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.colors.light0};
  background: ${({ theme }) => theme.colors.dark80};
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  line-height: 16px;
  padding: 0.25rem 0.5rem;
  @media ${tablet} {
    padding: 4px 7px;
    font-size: 14px;
    line-height: 14px;
    bottom: -11px;
  }
`;

const EBookTagMin = styled(Text).attrs({
  color: 'light0',
})`
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.colors.light0};
  background: ${({ theme }) => theme.colors.dark80};
  border-radius: 4px;
  font-style: normal;
  font-size: 8px;
  line-height: 16px;
  padding: 0.125rem 0.25rem;
  @media ${tablet} {
    padding: 1px 3.5px;
    font-size: 6px;
    line-height: 14px;
    bottom: -11px;
  }
`;

type BookImageProps = {
  coverUrl: string;
  alt: string;
  textColor?: string;
  userName?: string;
  isEBook?: boolean;
  isBackground?: boolean;
  top?: string;
  fontSize?: string;
  width?: string;
  ebookMin?: boolean;
  height?: string;
};

const BookImage = ({
  coverUrl,
  top,
  alt,
  fontSize,
  width,
  height,
  textColor,
  isBackground,
  userName,
  isEBook,
  ebookMin,
}: BookImageProps) => (
  <ImageContainer>
    {isBackground && <StyledDynamicImageBackground />}
    <StyledDynamicImage
      src={coverUrl}
      alt={alt}
      $width={width}
      $height={height}
    />
    {userName && textColor && fontSize && (
      <BookTitle $textColor={textColor} $top={top} $fontSize={fontSize}>
        {userName}
      </BookTitle>
    )}
    {isEBook &&
      (ebookMin ? (
        <EBookTagMin>ebook</EBookTagMin>
      ) : (
        <EBookTag>ebook</EBookTag>
      ))}
  </ImageContainer>
);

export default BookImage;
