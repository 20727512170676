import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { PlatformType } from './HowToSection';

interface PlatformSelectorProps {
  platform: PlatformType;
  onSelect: Dispatch<SetStateAction<PlatformType>>;
  iosTabTitle: string;
  androidTabTitle: string;
}

const Container = styled.div`
  // width: 100%;
  // max-width: 93px;
  border-radius: 52px;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => '#804DA1'};
  border: 1px solid ${({ theme }) => '#804DA1'};
`;

const PlatformSection = styled.button<{ isActive: boolean }>`
  padding: 7px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 52px;
  width: 93px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.light0 : '#804DA1'};
  cursor: pointer;
  border: none;
`;

const PlatformSelector: FC<PlatformSelectorProps> = ({
  platform,
  onSelect,
  iosTabTitle,
  androidTabTitle,
}) => {
  const handleIOS = () => {
    onSelect('ios');
  };

  const handleAndroid = () => {
    onSelect('android');
  };

  const isAndroidAcive = platform === 'android';

  const isIOSAcive = platform === 'ios';

  return (
    <Container>
      <PlatformSection isActive={isIOSAcive} onClick={handleIOS}>
        <Text type="bodyM500" color={isIOSAcive ? 'primaryDark' : 'light0'}>
          {iosTabTitle}
        </Text>
      </PlatformSection>
      <PlatformSection isActive={isAndroidAcive} onClick={handleAndroid}>
        <Text type="bodyM500" color={isAndroidAcive ? 'primaryDark' : 'light0'}>
          {androidTabTitle}
        </Text>
      </PlatformSection>
    </Container>
  );
};

export default PlatformSelector;
